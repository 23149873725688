:global {
.mobile-only {
    @include all-props((
        display: (
            0: block,
            1024: none,
        ),
    ));
}

.desktop-only {
    @include all-props((
        display: (
            0: none,
            1024: block,
        ),
    ));
}

.content-to-footer--padding {
    @include all-props((
        padding-bottom: (
            0: 120px,
            1024: 80px,
        )
    ));
}

.app-footer-root {
    background-color: #F2F2F2;

};

.app-footer-indents-wrapper {
    @include responsive-mobile-props(390px, (
        padding-top: 36px,
        padding-bottom: 41px,
    ));
    @include desktop-props((
        padding: 0,
    ));
}

.app-footer-width-container {
    @include responsive-desktop-props(1680px, (
        // padding-top: 46px,
        // padding-bottom: 24px,
        width: 1160px,
        // padding-left: 252px,
        // padding-right: 252px,
    ));

    @include desktop-props((
        margin: 0 auto,
    ));

}

.app-footer-credits {
    @include responsive-desktop-props(1680px, (
        padding-top: 22px,
        padding-bottom: 22px,
    ));

}

.app-footer-creadits--divider {
    @include mobile-props((
        border: none,
    ));

    @include desktop-props((
        border-top: 1px solid #EAEAEA,
    ));
}

.app-footer-content-limiter {
    @include responsive-mobile-props(390px, (
        padding-left: 20px,
        padding-right: 20px,
    ));

    @include all-props((
        margin: 0 auto,
        padding-left: (
            428: 0px,
            1024: 0px,
        ),
        padding-right: (
            428: 0px,
            1024: 0px,
        ),
    ));

    @include mobile-props((
        max-width: 428px,
    ));
}

.app-footer-row {
    @include all-props((
        font-family: $gilroy,
        // color: #9C9C9C,
        color: #C4C4C4,
    ));

    @include mobile-props((
        display: flex,
        justify-content: space-between,
    ));

    &.four {
        @include mobile-props((
            flex-direction: column,
            align-items: flex-start,
        ));
        @include desktop-props((
            display: grid,
            grid-template-columns: repeat(3, 1fr)
        ));
        @include responsive-desktop-props(1536px, (
            gap: 20px,
        ))
    }

}

.app-footer-row-divider {
    &.one {
        @include responsive-mobile-props(390px, (
            padding-bottom: 18px,
            margin-bottom: 26px,
        ));

        @include mobile-props((
            border-bottom: 1px solid #EAEAEA,
        ));

        @include desktop-props((
            flex: 1 1,
            margin-bottom: 0px,
        ));
    }

    &.two {
        @include responsive-mobile-props(390px, (
            margin-bottom: 18px,
        ));

        @include desktop-props((
            flex: 1 1,
            margin-bottom: 0px,
        ));
    }

    &.three {
        @include responsive-mobile-props(390px, (
            padding-bottom: 28px,
            margin-bottom: 23px,
        ));

        @include mobile-props((
            border-bottom: 1px solid #EAEAEA,
        ));

        @include desktop-props((
            flex: 1 1,
            margin-bottom: 0px,
        ));
    }

}

.app-footer-row-left {

}

.app-footer-store-button-container {
    @include mobile-props((
        display: none,
    ));

    &.apple {
        @include mobile-props((
            width: 120px,
        ));

        @include responsive-desktop-props(1536px, (
            width: 120px,
            height: 40px,
        ));

    }

    &.google {
        @include mobile-props((
            width: 130px,
        ));

        @include responsive-desktop-props(1536px, (
            width: 130px,
            height: 40px,
        ));

    }

}



.app-footer-store__button {
    @include responsive-mobile-props(390px, (
        width: 132px,
        height: 44px,
    ));

    @include responsive-desktop-props(1680px, (
        width: 120px,
        height: 40px,
    ));
}

.app-footer-row__title {
    @include all-props((
        font-weight: (
            0: 700,
            1024: 600,
        ),
        color: #9c9c9c,
    ));

    @include responsive-mobile-props(390px, (
        font-size: 15px,
        line-height: 17px,
    ));

    @include responsive-desktop-props(1680px, (
        font-size: 15px,
        line-height: 17px,
        padding-bottom: 4px,
    ));
}

.app-footer-row__subtitle {
    @include responsive-mobile-props(390px, (
        font-size: 17px,
        line-height: 20px,
    ));

    @include responsive-desktop-props(1536px, (
        font-size: 16px,
        line-height: 20px,
        padding-bottom: 10px,
    ));

    @include all-props((
        font-weight: (
            0: 400,
            1024: 300,
        ),
        color: #9c9c9c,
    ));
}

.app-footer-row__link {
    @include responsive-mobile-props(390px, (
        font-size: 17px,
        line-height: 22px,
    ));

    @include responsive-desktop-props(1680px, (
        font-size: 16px,
        line-height: 17px,
        padding-bottom: 5px,
    ));

    @include all-props((
        display: block,
        color: #9c9c9c,
        font-weight: (
            0: 400,
            1024: 300,
        ),
    ));

    &:visited {
        color: #9c9c9c,
    }

    &.eng--link {
        padding: 0;
    }
}

.app-footer-row-right {
    @include responsive-mobile-props(390px, (
        font-size: 17px,
        line-height: 22px,
    ));
}

.app-footer-row__about {
    @include responsive-mobile-props(390px, (
        font-size: 15px,
        line-height: 15px,
        padding-bottom: 4px,
    ));

    @include responsive-desktop-props(1680px, (
        font-size: 15px,
        line-height: 17px,
    ));

    @include all-props((
        font-family: $gilroy,
        color: #9C9C9C,
        font-weight: (
            0: 400,
            1024: 300,
        ),
        display: flex,
        align-items: center,
    ));

    @include desktop-props((
        display: flex,
        align-items: center,
    ));
}

.app-footer-row__about-sk {
    @include responsive-mobile-props(390px, (
        font-size: 15px,
        padding-bottom: 4px,
        line-height: 20px,
    ));

    @include responsive-desktop-props(1680px, (
        font-size: 15px,
        padding-bottom: 4px,
        line-height: 18px,
    ));



    img {
        @include responsive-mobile-props(390px, (
            max-height: 24px,
        ));

        @include responsive-desktop-props(1680px, (
            max-height: 22px,
        ));
    }

    @include all-props((
        color: #9C9C9C,
        font-weight: (
            0: 400,
            1024: 300,
        ),
        display: flex,
        align-items: center,
    ));

    img {

    }
}

.app-footer-row__copy {
    @include responsive-desktop-props(1680px, (
        font-size: 14px,
        line-height: 18px,
    ));

    @include mobile-props((
        color: #D1D1D1,
    ));

    @include desktop-props((
        color: #9C9C9C,
    ));
}


.sk-logo {
    @include responsive-mobile-props(390px, (
        height: 20px,
        padding-right: 3px,
    ));

    @include responsive-desktop-props(1680px, (
        height: 18px,
        width: 24px,
        padding-right: 3px,
    ));
}

.app-footer-link-list-left, .app-footer-link-list-right {
    @include mobile-props((
        flex-basis: 48%,
    ));
}

.app-footer-social-icon {
    @include all-props((
        display: flex,
    ));

    @include responsive-mobile-props(390px, (
        gap: 8px,
    ));

    @include responsive-desktop-props(1536px, (
        gap: 8px,
    ));
}

.app-footer-social-icon__item {
    @include responsive-desktop-props(1536px, (
        height: 40px,
        width: 40px,
    ));
    & svg {
        @include responsive-desktop-props(1536px, (
            height: 40px,
            width: 40px,
        ));
    }
}

.app-footer-navigation {
    @include desktop-props((
        display: flex,
        flex-direction: row-reverse,
        justify-content: space-between,
    ));

    @include responsive-mobile-props(375px, (
        gap: 15px,
    ));
    @include responsive-desktop-props(1536px, (
        gap: 20px,
        padding-top: 46px,
    ));
}

.app-footer-stors {
    @include responsive-desktop-props(1680px, (
        gap: 10px,
    ));

    @include desktop-props((
        display: flex,
    ));
}

.app-footer-row-date {
    @include responsive-desktop-props(1680px, (
        gap: 20px,
    ));

    @include desktop-props((
        display: flex,
        flex-wrap: wrap,
        align-items: inherit,
    ));
}

.eng--link {
    font-family: $gilroy;
    @include responsive-desktop-props(1680px, (
        font-size: 15px,
        line-height: 17px,
    ));
}

// DESKTOP
.app-footer-column-sk-logo-wrap {
    @include responsive-desktop-props(1536px, (
        width: 24px,
        height: 18px,
    ));
}

.app-footer-column-sk-logo {
    width: 100%;
    height: 100%;
}
.app-footer-column-text {
    color: #9C9C9C;
    font-family: $gilroy;
    font-style: normal;
    font-weight: 400;
    opacity: 0.6;
    @include responsive-desktop-props(1536px, (
        font-size: 15px,
        line-height: 17px,
    ));
}
.app-footer-column-sk {
    @include desktop-props((
        display: flex,
    ));

    @include responsive-desktop-props(1536px, (
        gap: 4px,
    ));
}
.app-footer-creadits-flex-wrapper {
    display: flex;
    width: 100%;

    @include responsive-desktop-props(1536px, (
        gap: 20px,
    ));

}

.app-footer-row__copy {
    @include desktop-props((
        color: #9C9C9C,
        font-family: $gilroy,
        font-weight: 400,
        opacity: 0.6,
    ));

    @include responsive-desktop-props(1536px, (
        font-size: 15px,
        line-height: 18px,
    ));
}

.app-footer-column  {
    @include responsive-desktop-props(1536px, (
        width: 420px,
    ));
}

.app-footer-column {
    & .eng--link {
        @include responsive-desktop-props(1536px, (
            font-size: 16px,
            line-height: 17px,
        ));
    }

    & .app-footer-row__about {
        color: #9C9C9C;
        @include responsive-desktop-props(1536px, (
            font-size: 16px,
            line-height: 17px,
        ));
    }
}

.app-footer-column-flex-wrap {
    display: flex;
    @include responsive-desktop-props(1536px, (
        gap: 10px,
    ));
}

// BR-1084 Profile footer
.profile-footer-wrapper {
    & .app-footer-column-flex-wrap {
        display: flex;
        @include responsive-desktop-props(1536px, (
            gap: 20px,
        ));
    }


    & .app-footer-root {
        @include all-props((
            display: (
                0: none,
                1024: block,
            ),
        ));
        & .app-footer-indents-wrapper {
            & .app-footer-navigation {
                @include responsive-desktop-props(1536px, (
                    gap: 20px,
                    padding-top: 42px,
                ));
            }
            & .app-footer-width-container {
                @include desktop-props((
                width: (
                    1024: 960px,
                    1280: 1200px,
                    1680: 1313px,
                    1920: 1350px,
                    2560: 1500px,
                ),
                ));
                @include responsive-desktop-props(1536px, (
                    width: 1300px,
                ));

                & .app-footer-row-divider {
                    &:not(.four) {
                        @include responsive-desktop-props(1536px,(
                            padding-bottom: 26px,
                        ));
                    }

                    & .app-footer-row-left {
                        & .app-footer-row__subtitle  {
                            @include all-props((
                                font-size: 16px,
                                line-height: 18px,
                            ));
                            @include responsive-desktop-props(1536px,(
                                font-size: 16px,
                                line-height: 18px,
                            ));
                            & .app-footer-row-call-ee {
                                display: none;
                            }
                        }
                    }

                    & .app-footer-row-right {
                        & .app-footer-social-icon {
                            & svg {
                                @include responsive-desktop-props(1536px, (
                                    width: 40px,
                                    height: 40px,
                                ));
                            }
                        }
                    }

                    & .app-footer-content-limiter {
                        & .app-footer-row__title {
                            @include responsive-desktop-props(1536px, (
                                font-size: 15px,
                                line-height: 17px,
                            ));

                        }
                        & .app-footer-link-list-left, & .app-footer-link-list-right {
                            & .app-footer-row__link {
                                @include responsive-desktop-props(1536px, (
                                    font-size: 16px,
                                    line-height: 18px,
                                    padding-bottom: 5px,
                                ));
                            }
                        }
                        & .app-footer-stors {
                            & svg {
                                @include all-props((
                                    height: 100%,
                                    width: 100%,
                                ));

                                // @include all-props((
                                //     width: (
                                //         1024: 120px,
                                //     ),
                                //     height: (
                                //         1024: 40px,
                                //     ),
                                // ));

                                // height: 100%;
                                // @include responsive-desktop-props(1536px, (
                                //     width: 120px,
                                // ));
                            }
                        }
                    }
                }
            }
            & .app-footer-creadits--divider {
                & .app-footer-credits  {
                    @include responsive-desktop-props(1536px, (
                        padding-top: 20px,
                        padding-bottom: 17px,
                    ));
                    & .app-footer-row-divider  {
                        & .app-footer-row {
                            @include desktop-props((
                                align-items: center,
                            ));
                            & .app-footer-row-date {
                                @include desktop-props((
                                    align-items: center,
                                ));
                                @include responsive-desktop-props(1536px, (
                                    padding-right: 27px,
                                    gap: 14px,
                                ));
                            }
                            & .app-footer-row__about {
                                @include desktop-props((

                                    justify-content: center,
                                ));
                                @include responsive-desktop-props(1536px, (
                                    font-size: 15px,
                                    line-height: 17px,
                                ));
                            }
                            & .app-footer-row__about-sk {
                                @include responsive-desktop-props(1536px, (
                                    font-size: 15px,
                                    line-height: 17px,
                                ));
                                & svg {
                                    @include responsive-desktop-props(1536px, (
                                        width: 24px,
                                        height: 18px,
                                    ));
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
}
