@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.dishes-selection-close-layer {
    @include desktop-props((
        box-sizing: border-box,
        z-index: 3000,
        right: 0,
        left: 0,
        bottom: 0,
        top: 0,
        position: absolute,
        background-color: transparent,
    ));

    @include mobile-props((
        display: none,
    ));

    &.entered {
        @include desktop-props((
            display: block,
        ));
        @include mobile-props((
            display: none,
        ));

    }
    &.exited {
        @include desktop-props((
            display: none,
        ));
        @include mobile-props((
            display: none,
        ));

    }
}

.dishes-selection__wrap {
    @include all-props((
        position: fixed,
        top: 0,
        height: 100%,
        background: var(--color__coconut),
        z-index: 10000,
        display: flex,
        flex-direction: column,
    ));

    @include mobile-props((
        width: 100%,
        left: 0,
        will-change: transform,
    ));

    @include desktop-props((
        width: 452px,
        will-change: right,
    ));


    &.exited {
        @include mobile-props((
            transform: translateX(100%),
        ));
        @include desktop-props((
            transition: right 1000ms,
            right: -100%,
        ));
    }
    &.entering {
        @include mobile-props((
            transition: transform 500ms,
            transform: translateX(0%),
        ));
        @include desktop-props((
            transition: right 1000ms,
            right: 0%,
        ));
    }
    &.entered {
        @include mobile-props((
            transform: translateX(0%),
        ));
        @include desktop-props((
            transition: right 1000ms,
            right: 0%,
        ));
    }
    &.exiting {
        @include mobile-props((
            transition: transform 500ms,
            transform: translateX(100%),
        ));
        @include desktop-props((
            transition: right 1000ms,
            right: -100%,
        ));
    }


    &.basket_realm {
        @include all-props((
            z-index: 10000,
        ));
    }
    &.menu_realm {
        @include all-props((
            z-index: 1000,
        ));
    }
}

.dishes-selection__header {
    @include all-props((
        box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.04),
    ));
}
.dishes-selection__footer {
    @include all-props((
        transform: translateZ(0),
        margin-top: (
            0:   30px,
            375: 40px,
            1024: 40px,
        ),
    ));
}

.dishes-selection__content {
    @include all-props((
        flex: 10,
        overflow-y: scroll,
    ));

    &::-webkit-scrollbar { // chrome, safari, opera
        @include all-props((
            display: none,
        ));
    }
}

// .dishes-selection__list {
//     @include desktop-props((
//         padding: 0 20px,
//     ));
// }


/*  SelectionPopOverBanner  */
.selection-popover-banner-wrapper {
    @include all-props((
        display: flex,
        justify-content: center,
        align-items: center,
        flex-direction: column,
        margin: (
           0:  16px 0 30px,
           375: 20px 0 40px,
           1024: 20px 0 30px,
        ),
    ));
    &.category-selection {
        @include mobile-props((
            margin: (
                0:  10px 0,
                375: 13px 0,
            ),
        ))
    }
}
.selection-poppver-banner-title {
    text-align: start !important;
    width: 100%;
    box-sizing: border-box;
    @include all-props((
        padding: (
            0: 14px 10px 0 20px,
            360: 15px 10px 0 22px,
            375: 18px 10px 0 21px,
            414: 20px 10px 0 22px,
            1024: 20px 10px 0 22px,
        ),
    ));

    @include all-props((
        font-family: $gilroy,
        font-weight: (
            0:    700,
            1024: 600,
        ),
        color: #fff,
        font-size: (
            0: 25px,
            375: 27px,
            414: 30px,
            1024: 30px,
        ),
        letter-spacing: (
            0: -0.3px,
            375: -0.4px,
            1024: -0.4px,
        ),
        line-height: (
            0: 24px,
            375: 28px,
            1024: 28px,
        ),
    ));

    &.black {
        color: #424242;
    }
    &.centered {
        text-align: center;
        @include all-props((
            padding: (
                0: 14px 14px 0 14px,
                360: 15px 15px 0 15px,
                375: 18px 18px 0 18px,
                414: 20px 20px 0 20px,
                1024: 20px 20px 0 20px,
            ),
        ))
    }


    img {
        @include all-props((
            // like line-height
            height: (
                0: 24px,
                375: 28px,
                1024: 28px,
            ),
        ))
    }

}
.selection-poppver-banner-description {
    & :global(.link) {
        text-decoration: underline;
    }
    & :global(.dotted) {
        position: relative;
        &::before {
            content: '';
            background: url('./img/dotted-yap.img.svg') center center repeat-x;
            @include all-props((
                width: 100%,
                z-index: 5,
                position: absolute,
                height: 3px,
                bottom: 0px,
            ));
        }
    }

    & :global(.m) {
        @include mobile-props((
            display: (
                0: block,
                375: none,
            )
        ))
    }

    line-height: 100%;
    @include all-props ((
        width: (
            0: 90vw,
            1024: 410px,
        ),
    ));
}

.m-small {
    @include all-props ((
        padding-top: (
            0: 8px,
            375: 14px,
            1024: 14px,
        ),
        width: (
            415: 384px,
            1024: 410px,
        )
    ));

}
.m-big,
.m-medium {
    @include all-props ((
        margin-top: (
            0: 7px,
            375: 8px,
            414: 12px,
            1024: 12px,
        ),
        padding-top: 0px,
        width: (
            415: 384px,
            1024: 410px,
        )
    ));

}

